export const usersToDetailMock = [
  {
    id: 0,
    name: "Fulano da silva 0",
    phone: "25 99999-9999",
    email: "fulano0@bol.com",
    step: 1,
  },
  {
    id: 1,
    name: "Fulano da silva 1",
    phone: "25 99999-9999",
    email: "fulano1@bol.com",
    step: 1,
  },
  {
    id: 2,
    name: "Fulano da silva 2",
    phone: "25 99999-9999",
    email: "fulano2@bol.com",
    step: 1,
  },
  {
    id: 3,
    name: "Fulano da silva 3",
    phone: "25 99999-9999",
    email: "fulano3@bol.com",
    step: 1,
  },
  {
    id: 4,
    name: "Fulano da silva 4",
    phone: "25 99999-9999",
    email: "fulano4@bol.com",
    step: 1,
  },
  {
    id: 5,
    name: "Fulano da silva 5",
    phone: "25 99999-9999",
    email: "fulano5@bol.com",
    step: 1,
  },
  {
    id: 6,
    name: "Fulano da silva 6",
    phone: "25 99999-9999",
    email: "fulano6@bol.com",
    step: 1,
  },
  {
    id: 7,
    name: "Fulano da silva 7",
    phone: "25 99999-9999",
    email: "fulano7@bol.com",
    step: 1,
  },
  {
    id: 8,
    name: "Fulano da silva 8",
    phone: "25 99999-9999",
    email: "fulano8@bol.com",
    step: 1,
  },
  {
    id: 9,
    name: "Fulano da silva 9",
    phone: "25 99999-9999",
    email: "fulano9@bol.com",
    step: 1,
  },
  {
    id: 10,
    name: "Fulano da silva 10",
    phone: "25 99999-9999",
    email: "fulano10@bol.com",
    step: 1,
  },
  {
    id: 11,
    name: "Fulano da silva 11",
    phone: "25 99999-9999",
    email: "fulano11@bol.com",
    step: 1,
  },
  {
    id: 12,
    name: "Fulano da silva 12",
    phone: "25 99999-9999",
    email: "fulano12@bol.com",
    step: 1,
  },
  {
    id: 13,
    name: "Fulano da silva 13",
    phone: "25 99999-9999",
    email: "fulano13@bol.com",
    step: 1,
  },
  {
    id: 14,
    name: "Fulano da silva 14",
    phone: "25 99999-9999",
    email: "fulano14@bol.com",
    step: 1,
  },
  {
    id: 15,
    name: "Fulano da silva 15",
    phone: "25 99999-9999",
    email: "fulano15@bol.com",
    step: 1,
  },
  {
    id: 16,
    name: "Fulano da silva 16",
    phone: "25 99999-9999",
    email: "fulano16@bol.com",
    step: 1,
  },
  {
    id: 17,
    name: "Fulano da silva 17",
    phone: "25 99999-9999",
    email: "fulano17@bol.com",
    step: 1,
  },
  {
    id: 18,
    name: "Fulano da silva 18",
    phone: "25 99999-9999",
    email: "fulano18@bol.com",
    step: 1,
  },
  {
    id: 19,
    name: "Fulano da silva 19",
    phone: "25 99999-9999",
    email: "fulano19@bol.com",
    step: 1,
  },
  {
    id: 20,
    name: "Fulano da silva 20",
    phone: "25 99999-9999",
    email: "fulano20@bol.com",
    step: 1,
  },
  {
    id: 21,
    name: "Fulano da silva 21",
    phone: "25 99999-9999",
    email: "fulano21@bol.com",
    step: 1,
  },
  {
    id: 22,
    name: "Fulano da silva 22",
    phone: "25 99999-9999",
    email: "fulano22@bol.com",
    step: 1,
  },
  {
    id: 23,
    name: "Fulano da silva 23",
    phone: "25 99999-9999",
    email: "fulano23@bol.com",
    step: 1,
  },
  {
    id: 24,
    name: "Fulano da silva 24",
    phone: "25 99999-9999",
    email: "fulano24@bol.com",
    step: 1,
  },
];

export const journeyMock = {
  id: "691d2026-793b-4e53-8e11-f23d88d5417e",
  label: "TODOS_andresss",
  stepLabel: "TODOS_andresss",
  type: "selectAudience",
  wasRenamed: false,
  selectedContentId: "",
  isDataSelected: true,
  formatType: "circle",
  hasNextNode: true,
  color: "#9747ff",
  icon: "mdi-calendar",
  lastNode: null,
  nextNode: {
    id: "aa372b6e-7e6e-4f8d-a02c-1e00dffb7c81",
    label: "Teste_andre_sms_time",
    stepLabel: "Teste_andre_sms_time",
    type: "sendSMS",
    formatType: "square",
    wasRenamed: false,
    hasNextNode: true,
    icon: "mdi-message-processing",
    color: "#0b99ff",
    selectedContentId: "",
    isDataSelected: true,
    lastNode: "691d2026-793b-4e53-8e11-f23d88d5417e",
    nextNode: {
      id: "1b7e97ac-fb2c-4ed7-adbe-dd97b30d76bf",
      label: "Div. por interação",
      stepLabel: "Div. por interação",
      type: "mensageriaCheck",
      formatType: "diamond",
      wasRenamed: false,
      selectedContentId: "",
      hasNextNode: true,
      icon: "mdi-email",
      color: "#9747ff",
      mensageriaStepCheckType: "open",
      isDataSelected: true,
      lastNode: "aa372b6e-7e6e-4f8d-a02c-1e00dffb7c81",
      YesNext: {
        id: "050f43c6-2bda-435e-ab99-d9217f5ec73c",
        label: "5 Dias",
        stepLabel: "5 Dias",
        type: "await",
        icon: " $timer_rounded",
        formatType: "circle",
        wasRenamed: false,
        selectedContentId: "",
        hasNextNode: true,
        isDataSelected: true,
        color: "#9747ff",
        lastNode: "1b7e97ac-fb2c-4ed7-adbe-dd97b30d76bf",
        nextNode: null,
        fromClick: "yes",
        YesNext: {
          id: "af75ed40-be04-4eda-8f2a-1242ee96855e",
          label: "Final",
          stepLabel: "",
          type: "final",
          wasRenamed: false,
          selectedContentId: "",
          formatType: "circle",
          hasNextNode: false,
          color: "#2bae5c",
          isDataSelected: false,
          icon: "$exit_to_app_rounded",
          nextNode: null,
          lastNode: "050f43c6-2bda-435e-ab99-d9217f5ec73c",
          fromClick: "yes",
        },
        wait: "Dias",
        waitValue: "5",
        shouldValidate: false,
      },
      NoNext: null,
      nextNode: {
        id: "0bb471bf-3954-444f-89e6-7268c92154bd",
        label: "Final",
        stepLabel: "",
        wasRenamed: false,
        type: "final",
        isDataSelected: false,
        formatType: "circle",
        hasNextNode: false,
        color: "#2bae5c",
        selectedContentId: "",
        icon: "$exit_to_app_rounded",
        nextNode: null,
        lastNode: "1b7e97ac-fb2c-4ed7-adbe-dd97b30d76bf",
        fromClick: "next",
      },
      fromClick: "next",
      mensageriaStepCheckId: "aa372b6e-7e6e-4f8d-a02c-1e00dffb7c81",
      lastAudience: "95ede9b9-464d-4680-8591-f794892fcfa4",
      shouldValidate: false,
    },
    fromClick: "next",
    templateId: "28b90c3d-673c-422e-9002-472aad891930",
    shouldValidate: false,
  },
};
